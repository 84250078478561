import { useState } from "react";
import "./styles.css";
import "./media.css";

// Main
import Canvas from "./c/Canvas";
import Wrapper from "./c/Wrapper";
import Input from "./c/Input";
import Items from "./c/Items";

// Ui
import Ui from "./u/Ui";

// Functionals
import { hexToRgb } from "./f/colors.js";
import { saveCurrent, loadPrevious } from "./f/storage.js";

/* Main APP
 */
export default function App() {
  // handle state
  const [colors, setColors] = useState([]);
  const colorHandler = (c) => {
    let value = hexToRgb(c);
    if (value.vec !== null) setColors([...colors, value]);
  };

  // handle delete item
  const handleDelete = (index) => {
    const newArray = colors.filter((item, i) => i !== index);
    setColors([...newArray]);
  };

  // handle save state
  document.onkeydown = (e) => {
    if (e.key === "S") {
      saveCurrent(colors);
    } else if (e.key === "L") {
      setColors(loadPrevious());
    }
  };

  return (
    <div className="App">
      <Canvas />

      <Wrapper>
        <Input event={colorHandler} />
        <Items colors={colors} deleteItem={handleDelete} />
      </Wrapper>

      <Ui />
    </div>
  );
}
