import "./Heading.css";

export default function () {
  return (
    <div className="Heading">
      <h1>Hex (to)Vec</h1>
      <p>
        So I can stop screaming at Figma, XD, Sketch or the designer who gives
        me HEX.
      </p>
    </div>
  );
}
