import "./Input.css";

import { checkValid } from "../f/colors.js";

export default function ({ event }) {
  // handle data function
  const recieveValue = (val) => {
    if (checkValid(val)) {
      event(val);
    } else {
      shakeWrong();
    }
  };

  // EVENT: paste
  document.onpaste = (e) => {
    recieveValue(e.clipboardData.getData("Text"));
  };
  // EVENT: enter on input
  document.onkeyup = (e) => {
    if (e.key === "Enter") {
      const input = document.querySelector("input");
      const val = input.value;

      if (checkValid(val)) {
        event(val);
        input.value = "";
      } else shakeWrong();
    }
  };

  return (
    <div className="is-flex">
      <input
        className="Input"
        placeholder="Enter or Paste, 3 / 6 / 7(#) chars HEXs work."
      ></input>
    </div>
  );
}

/* Animation Functions */
function shakeWrong() {
  const input = document.querySelector("input");

  if (input.classList.contains("an-shake")) input.classList.remove("an-shake");
  setTimeout(() => {
    input.classList.add("an-shake");
    input.value = "";
  }, 1);

  input.focus();
}
