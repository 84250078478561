import colors from "hex-to-color-name";
import htmlColors from "html-colors";

/* Function */
export function hexToRgb(hex) {
  // 1. utils and uniform
  hex = expandShorthand(hex);
  // 3. return
  return {
    vec: hexToVec(hex),
    name: getColorName(hex),
    hex: "#" + hex
  };
}

function expandShorthand(hex) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  return hex;
}

function hexToVec(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255
      }
    : null;
}

function getColorName(hex) {
  let nameSmall = colors(hex, htmlColors.all());
  const name = nameSmall.charAt(0).toUpperCase() + nameSmall.slice(1) + ".";
  return { name, nameSmall };
}

/* Utils */
// check validity
export function checkValid(hex) {
  hex = expandShorthand(hex);
  hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return hex !== null;
}
