import "./Item.css";

import Tile from "./Tile";
import { clipWrite } from "../f/ui.js";

export default function ({ index, data, deleteItem }) {
  // paste data in clipboard
  const clickHandler = () => {
    let info = `vec3(${data.vec.r}, ${data.vec.g}, ${data.vec.b});`;
    clipWrite(info);
  };

  const deleteHandler = () => {
    deleteItem(index);
  };

  return (
    <div className="item-w">
      <div className="Item" onClick={clickHandler}>
        <div className="item-row">
          <h3>{data.name.name}</h3>
          <div className="item-info">
            <p className="tx-upper">{data.hex}</p>
            <Tile color={data.hex} />
          </div>
        </div>

        <div className="item-row space-top">
          <p className="item-hex">
            vec3({data.vec.r}, {data.vec.g}, {data.vec.b});
          </p>
        </div>
      </div>

      <div className="line"></div>

      <div className="btn-c">
        <div className="btn-w" onClick={deleteHandler}>
          <div className="btn-in">
            <p>X</p>
          </div>
        </div>
      </div>
    </div>
  );
}
