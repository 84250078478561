import { useState } from "react";

import "./Ui.css";

import Heading from "./Heading";
import ToggleTitle from "./ToggleTitle";
import UiItem from "./UiItem";

export default function () {
  // STATE: toggles
  const [toggles, setToggles] = useState([false, false]);
  const handleToggles = (i) => {
    const updated = toggles;
    updated[i] ? (updated[i] = false) : (updated[i] = true);
    setToggles([...updated]);
  };

  // toggle on / off
  return (
    <div className="Ui">
      <Heading />
      <div className="ui-c">
        <UiItem title={CONTENT.guide.title} text={CONTENT.guide.text} />
        <UiItem title={CONTENT.saving.title} text={CONTENT.saving.text} />
      </div>

      <div>
        <UiItem title="" text={CONTENT.sign.text} />
      </div>
    </div>
  );
}

// populate
const CONTENT = {
  guide: {
    title: "How to.",
    text:
      'Both Enter on the Input and "Paste" (on all the page)work. Click on any color and copy the relevant GLSL code.'
  },
  saving: {
    title: "Saving.",
    text:
      "State can be saved to localstorage. Use S to save and L to load previous saved state. It's not like bulletproof but kinda works."
  },
  usev4: {
    title: "Use Vec4.",
    text: "Change default copy-paste code to use vec4 instead of vec3."
  },
  prefix: {
    title: "Prefix",
    text: "Export the full line, including the variable declaration."
  },
  sign: {
    text: "👀"
  }
};
