import "./UiItem.css";

export default function ({ text, title }) {
  return (
    <div className="uiblock-w">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
}
