const config = {
  key: "hextovec_past_session_data"
};

/* SAVE to localstorage */
export function saveCurrent(data) {
  localStorage.setItem(config.key, JSON.stringify(data));
}

/* CHECK & loadlocalstorage */
export function checkStorage() {
  const data = localStorage.getItem(config.key);
  if (data !== null) {
    if (data.length > 0) return data;
  }
  return false;
}

export function loadPrevious(data) {
  const loaded = checkStorage();

  if (loaded !== false) {
    data = JSON.parse(loaded);
    return data;
  } else return [];
}
