/* Cilpbpoard Ops */
export function clipGet() {
  return navigator.clipboard.read();
}

export function clipWrite(data) {
  const blob = new Blob([data], { type: "text/plain" });
  const item = new ClipboardItem({ "text/plain": blob });
  navigator.clipboard.write([item]);
}
