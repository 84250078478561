import "./Items.css";

import Item from "./Item";

export default function ({ colors, deleteItem }) {
  //console.log(colors);

  return (
    <div className="Items">
      {colors.map((col, i) => {
        return <Item key={i} index={i} data={col} deleteItem={deleteItem} />;
      })}
    </div>
  );
}
